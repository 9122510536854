// @flow
import * as React from 'react';

import I18N from 'lib/I18N';
import Icon from 'components/ui/Icon';
import Spacing from 'components/ui/Spacing';
import { asButton } from 'components/Navbar/util';

export default function HelpButton(): React.Element<typeof React.Fragment> {
  const helpButtonRef = React.useRef();

  const onButtonClick = React.useCallback(() => {
    // redirect to help pdf
    window.open('/images/JEDI User Manual.pdf', '_blank');
  }, []);

  // We use 'top' instead of setting the margin or padding because
  // it is the only way to position the icon without also modifying the
  // position of the text.
  const icon = (
    <Spacing marginRight="xxs" style={{ display: 'inline-block', top: 2 }}>
      <Icon type="question-sign" />
    </Spacing>
  );

  return (
    <React.Fragment>
      <span ref={helpButtonRef}>
        {asButton(
          () => onButtonClick(),
          I18N.text('Help'),
          false,
          null,
          '',
          'help-button',
          icon,
        )}
      </span>
    </React.Fragment>
  );
}
